<template>
  <div :class="$style.main">
    <OrganismsLayoutDefault>
      <template #nuxt-page>
        <NuxtPage />
      </template>

      <template #footer-fixed-contents>
        <OrganismsFooterFixedContents :min-screen-md="minScreenMd" />
      </template>
    </OrganismsLayoutDefault>
  </div>
</template>

<script setup lang="ts">
const { width: windowWidth } = useWindowSize()
const minScreenMd = computed(() => windowWidth.value >= 768)
</script>

<style module lang="scss" scoped>
.main {
  min-width: 100%;
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
}
</style>
